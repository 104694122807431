#ReportContainer {
  margin-top: -5px;
  margin-bottom: 18px;
  margin-left: 8px;
  margin-right: 8px;
  display: flex;
  /* justify-content: center; */
}

#ReportContainerOptions {
  display: flex;
  margin-right: 10px;
  padding-right: 5px;
  border-right: 1px solid #cecece;
  align-items: center;
}

.imageButtonReport {
  height: 15px;
  width: 15px;
  fill: white;
}

@media (max-width: 600px) {
  #ReportContainer {
    justify-content: space-between;
  }

  #ReportContainerOptions {
    padding-right: 0;
    margin-right: 0;
    border-right: none;
  }

  .imageButtonReport {
    height: 20px;
    width: 20px;
  }
}

.report {
  height: calc(100vh - 60px);
  margin-top: -15px;
  width: 100%;
  position: relative;
}
.topbar {
  height: 30px;
  display: flex;
  align-items: center;
}
.topbar a {
  color: var(--color-white);
  font-size: 18px;
  padding: 0 20px;
}
